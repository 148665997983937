import sumBy from 'lodash/sumBy';

import { Offer, OfferExpense, OfferInstallationExpense, OfferProducts, ProductInput } from '~src/types';

import { filterFalsy, withVAT } from './calculationHelper';

export const sortSentOffers = (offer?: Pick<Offer, 'sentOffers'>) =>
  offer?.sentOffers ? [...offer.sentOffers].sort((a, b) => (b.sentAt ?? 0) - (a.sentAt ?? 0) || b.id - a.id) : [];

export const findInstallationExpensesForOffer = <T extends OfferInstallationExpense>(
  offer?: Pick<Offer, 'heatPumpOutdoorUnit' | 'solarPanel' | 'battery'>,
  installationExpenses?: { heatPump: T[]; solar: T[] }
) => {
  if (!offer || !installationExpenses) {
    return [];
  }

  const { heatPump, solar: solarExpenses } = installationExpenses;

  const { heatPumpOutdoorUnit, solarPanel } = offer;

  if (!solarPanel) {
    return heatPump;
  }

  const solar = findSolarInstallationExpenses(offer, solarExpenses);

  if (!heatPumpOutdoorUnit) {
    return solar;
  }

  return [...heatPump, ...solar];
};

const findSolarInstallationExpenses = (
  { solarPanel, battery }: Pick<Offer, 'heatPumpOutdoorUnit' | 'solarPanel' | 'battery'>,
  solarExpenses: OfferInstallationExpense[]
) => {
  const { quantity = 0 } = solarPanel ?? {};

  const installationSpecialKeys = new Set(['inverter_installation', ...(battery ? ['battery_installation'] : [])]);

  const sortedSolarExpenses = solarExpenses.sort((a, b) => (b.minimumProducts ?? 0) - (a.minimumProducts ?? 0));

  const solarPanelExpense = sortedSolarExpenses.find(
    ({ minimumProducts = 0, specialKey }) =>
      quantity >= (minimumProducts ?? 0) && specialKey === 'solar_panel_installation'
  );

  return [
    ...solarExpenses.filter(({ specialKey }) => installationSpecialKeys.has(specialKey ?? '')),
    ...(solarPanelExpense
      ? [
          {
            ...solarPanelExpense,
            price: (solarPanelExpense.price ?? 0) * quantity,
            buyPrice: (solarPanelExpense.buyPrice ?? 0) * quantity,
          },
        ]
      : []),
  ];
};

export const formatProductsForOfferInput = (input?: (ProductInput | undefined)[]) => {
  const filtered = filterFalsy(input) as ProductInput[];

  return filtered.map((unit: ProductInput) => ({
    id: unit.id,
    price: unit.price,
    quantity: unit.quantity,
  }));
};

export const divideOfferExpenses = <T extends OfferExpense>(expenses: T[] = []) =>
  expenses.reduce(
    (result: { added: T[]; other: T[] }, next) => {
      const isAdded = !next.type && next.price >= 0;

      return {
        added: isAdded ? [...result.added, next] : result.added,
        other: isAdded ? result.other : [...result.other, next],
      };
    },
    { added: [], other: [] }
  );

export const offerPriceWithoutFees = (price: number, offerProducts?: Partial<Pick<OfferProducts, 'expenses'>>) =>
  price -
  sumBy(
    offerProducts?.expenses?.filter(({ type }) => type === 'required'),
    ({ price: expensePrice }) => withVAT(expensePrice)
  );
