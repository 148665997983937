import { global } from './global';
import { TranslationRecord } from './translationKeys';

const unexpectedError = 'Der skete en uventet fejl';

export const translations: TranslationRecord = {
  ...global,
  ACTIONS: 'Handlinger',
  ADD: 'Tilføj',
  ADDED_EXPENSES: 'Ekstra tillæg',
  ADD_CONDENSATION_PUMP: 'Tilføj kondensvandspumpe til tilbuddet',
  ADDRESS: 'Adresse',
  ADD_OFFER: 'Tilføj tilbud',
  ADMIN_PANEL: 'Admin Panel',
  AFTER: 'Efter',
  AIR_TO_AIR: 'Luft til luft',
  AIR_TO_WATER: 'Luft til vand',
  ALL: 'Alle',
  ALREADY_EXISTS: 'Findes allerede',
  ALUMINUM: 'Aluminum',
  AND: 'Og',
  ANNUAL_PRODUCTION: 'Årlig produktion',
  APARTMENT: 'Lejlighed',
  APRIL: 'April',
  ARCHIVE: 'Arkiver',
  ARCHIVE_OFFER: 'Arkiver tilbud',
  ARE_YOU_SURE: 'Er du sikker?',
  ASSIGNED_ADVISOR: 'Tildelt rådgiver',
  ASSORTED_PRODUCTS: 'Diverse produkter',
  AS_BUILT: 'Som opført',
  ATTACH: 'Vedhæft',
  ATTACHED: 'Vedhæftede',
  AUGUST: 'August',
  AUTHENTICATING: 'Godkender',
  ARCHIVE_EXISTING_OFFER: 'Arkivér tidligere tilbud',
  AWAITING: 'Afventer',
  AWAITING_CUSTOMER_ACCEPT: 'Afventer kundens accept',
  BACK: 'Tilbage',
  BATTERY: 'Batteri',
  BEFORE: 'Før',
  BEST_OFFER_IS_USED: 'Hvis flere solcelle/varmepumpe-tilbud er valgt, brugs de bedste av de valgte tilbudden.',
  BILLING: 'Fakturering',
  BIOCLASS_1: 'Bioklasse 1',
  BIOCLASS_2: 'Bioklasse 2',
  BODIL_ADVISOR_APP: 'Bodil Rådgiverapp',
  BODIL_HAS_PAID_THE_INSTALLER: 'Bodil har betalt installatøren',
  BOOK_INSTALLATION: 'Book installation',
  BOOK_SITE_VISIT: 'Book kundebesøg',
  BOUGHT_ELECTRICITY: 'Køb af strøm',
  BRAND: 'Mærke',
  BUILT_YEAR: 'Byggeår',
  CANCEL: 'Fortryd',
  CAPACITY: 'Kapacitet',
  CEILING: 'Loft',
  CERTIFICATION: 'Certifiering',
  CHANGE: 'Skift',
  CHOOSE_EXISTING_ADDRESS: 'Vælg eksisterende adresse',
  CLICK_TO_SORT: 'Klik for at sortere',
  COMPASS_DIRECTION: 'Kompasretning',
  COMPLETED: 'Gennemført',
  COMPLETE_INSTALLATION: 'Gennemfør installation',
  COMPLETE_SITE_VISIT: 'Gennemfør kundebesøg',
  CONDITION: 'Stand',
  CONSUMPTION: 'Forbrug',
  CONSUMPTION_INFORMATION: 'Forbrugsdata',
  CONSUMPTION_OF_WH_PER_KM: 'Forbrug af Wh pr. km',
  CONSUMPTION_PATTERN: 'Forbrugsmønstre',
  CONTINUE: 'Fortsæt',
  CONTINUE_TO_SEE_PRODUCTS: 'Klik “Fortsæt” for at se dine gemte produkter',
  CONTROL_QUESTIONS: 'Kontrolspørgsmål',
  COPPER: 'Kobber',
  CORRECTED_POTENTIAL_USAGE: 'Korrigeret potentielt forbrug',
  CORRESPONDING_TO_A_SAVING_OF: 'Svarende til en besparelse på',
  COPY_OFFER: 'Kopier tilbud',
  COULD_NOT_DOWNLOAD_FILE: 'Kunne ikke hente fil',
  CREATE: 'Opret',
  CREATED: 'Oprettet',
  CREATE_CHOSEN_OFFERS: 'Opret valgte tilbud',
  CREATE_CUSTOMER_AND_CONTINUE: 'Opret kunde og forsæt',
  CREATE_NEW_ADDRESS: 'Opret ny adresse',
  CREATE_NEW_CUSTOMER: 'Opret ny kunde',
  CREATE_NEW_USER: 'Opret ny bruger',
  CREATE_OFFER: 'Opret Tilbud',
  CREATE_RESIDENCE: 'Opret bolig',
  CREATE_RESIDENCE_DISCLAIMER:
    'Beregningerne er baseret på offentlig tilgængelig boligdata. Stemmer boligdata ikke, kan du rette dataen og få en mere præcis beregning.',
  CREATE_USER_ERROR: `${unexpectedError}. Verificer at du har indtastet en gyldig email og et password.`,
  CREATE_USER_QUESTION: 'Allerede bruger?',
  CREATING_OFFERS: 'Opretter tilbud',
  CURRENT: 'Nuværende',
  CURRENT_PRIMARY_AND_SECONDARY_USAGE: 'Primær og sekunder aktuelt forbrug',
  CURRENT_PRIMARY_USAGE: 'Primær aktuelt forbrug',
  CUSTOMER: 'Kunde',
  CUSTOMERS_ELECTRICITY_PRICE: 'Kundens elpris',
  CUSTOMER_BILLING: 'Fakturering af kunde',
  CUSTOMER_CREATED: 'Kunde oprettet',
  CUSTOMER_FINANCING_PROMPT: 'Ønsker kunden at modtage information om finansiering?',
  CUSTOMER_HEAT_PUMP_SUBSIDY_PROMPT: 'Skal varmepumpe tilskud medregnes?',
  CUSTOMER_HISTORY: 'Kundens historik',
  CUSTOMER_INFORMATION: 'Kundeinformation',
  CUSTOMER_INFLATION_PROMPT: 'Skal inflation medtages i kundens beregninger?',
  CUSTOMER_NOTES: 'Kundenoter',
  CUSTOMER_EMAIL_EXISTS: 'Denne email adresse findes allerede i systemet. Vælg kunden fra listen i stedet.',
  CUSTOM_EXPENSE_LABEL: 'Indtast selv...',
  CVR_NUMBER: 'CVR nummer',
  DAY: 'Dag',
  DAYS: 'Dage',
  DECEMBER: 'December',
  DELAY_EMAIL: 'Send først om en time',
  DELETE: 'Slet',
  DELETE_IMAGE: 'Slet billede',
  DELETES: 'Sletter',
  DESCRIPTION: 'Beskrivelse',
  DESELECT_TO_UPDATE: 'Tryk ud af feltet for at opdatere',
  DISCOUNT: 'Rabat',
  DISCOUNTS: 'Rabatter',
  DISCOUNTED_MARGIN: 'Dækningsbidrag (med rabat)',
  DETACHED_HOUSE: 'Parcelhus',
  DEPTH: 'Dybde',
  DID_NOT_SYNCHRONIZE: 'Kunne ikke synkronisere',
  DIMENSION_LABEL_BOX: 'B x L x H',
  DIMENSION_LABEL_CYLINDER: 'D x H',
  DISTRICT_HEATING: 'Fjernvarme',
  DISTRICT_HEATING_COMPARE: 'Sammenlign med Fjernvarme',
  DISTRICT_HEATING_CONNECTION_YEAR: 'Tilslutningsår',
  DISTRICT_HEATING_EFFICIENCY: 'Virkningsgrad (effektivitet)',
  DISTRICT_HEATING_INFORMATION: 'Fjernvarmeoplysninger',
  DISTRICT_HEATING_OLD_PRICE_PER_YEAR: 'Gamle varmekilder pris / år',
  DISTRICT_HEATING_PRICE_EFFECT: 'Effektbidrag',
  DISTRICT_HEATING_PRICE_HEAT_PUMP_SUPPLEMENTAL: 'Evt. extraudgift til varmepumpe (service, etc.)',
  DISTRICT_HEATING_PRICE_INSTALLATION: 'Etableringsbidrag',
  DISTRICT_HEATING_PRICE_INVESTMENT: 'Investering (tilslutningspris)',
  DISTRICT_HEATING_PRICE_RELAY: 'Målerbidrag',
  DISTRICT_HEATING_TOTAL_AFTER_YEARS: 'Total pris efter 15 år',
  DISABLED: 'Slået fra',
  DO_YOU_HAVE_OR_PLAN_ON_GETTING_AN_ELECTRIC_CAR: 'Har du elbil, eller skal du have indenfor de næste år?',
  DOWNLOAD_ALL_IMAGES: 'Hent alle billeder',
  DOWNLOAD_COMBINED_OFFER: 'Hent kombitilbud',
  DOWNLOAD_DATASHEETS: 'Hent datablade',
  DOWNLOAD_OFFER_FILES: 'Hent tilbudsfiler',
  EAST: 'Øst',
  ECONOMY: 'Økonomi',
  EDIT: 'Rediger',
  EFFECT: 'Effekt',
  ELECTRICITY_CONSUMPTION: 'Elforbrug',
  ELECTRICITY_PANEL: 'Eltavle',
  ELECTRIC_CAR: 'Elbil',
  ELECTRIC_CAR_IS_SUBSIDISED: 'Modtager du refusion?',
  ELECTRIC_CAR_MONTHLY_SUBSCRIPTION: 'Månedlig pris på abonnement',
  ELECTRIC_CAR_YEARLY_KWH_CONSUMPTION: 'Årligt forbrug i kWh på elbilen',
  ELECTRIC_HEATING: 'Elvarmesystem',
  ENERGY_LABEL: 'Energimærke',
  EXPECTED_KILOMETERS_PER_YEAR: 'Forventet antal km om året',
  EXPENSE: 'Tilbehør',
  EXPENSES: 'Udgifter',
  EXPENSES_ACCUMULATED: 'Samlede udgifter',
  EXPENSES_YEARLY: 'Årlige udgifter',
  EXPORT: 'Eksporter',
  EXTRA_CONSUMPTION_WITH_BATTERY: 'Ekstra forbrug med batteri',
  FEBRUARY: 'Februar',
  FILE: 'Fil',
  FILES: 'Filer',
  FINAL_OFFER: 'Endeligt tilbud',
  FINAL_OFFER_ACCEPTED: 'Endeligt tilbud accepteret',
  FINAL_OFFER_SENT: 'Endeligt tilbud sent',
  FINANCIAL_ADVISOR: 'Bankrådgiver',
  FINANCING: 'Finansiering',
  FINANCING_AFFILATION: 'Ja, tilhørsforhold til',
  FINANCING_PROVIDER: 'Lånudbyder',
  FIREPLACE: 'Åben pejs',
  FIRST_NAME: 'Fornavn',
  FLOOR_HEATING: 'Gulvvarme',
  FLOOR_PLAN: 'Plantegning',
  FORGOT_PASSWORD: 'Glemt adgangskode',
  FORM_FIELD_FORMAT_EMAIL: 'Email er ikke gyldig',
  FORM_FIELD_FORMAT_NUMBER: 'Nummeret er ikke gyldigt',
  FORM_FIELD_MAX_LENGTH: 'Længden må ikke overstige',
  FORM_FIELD_REQUIRED: 'er påkrævet',
  FUEL_TYPE: 'Brændselstype',
  FUTURE: 'Fremtidig',
  GAS_BOILER: 'Gasfyr',
  GO_TO_CUSTOMER: 'Gå til kunde',
  GRAPH: 'Graf',
  HEATED_LIVING_AREA: 'Opvarmet boligareal',
  HEAT_DISTRIBUTION: 'Varmefordeling',
  HEAT_EFFECT: 'Varmeeffekt',
  HEAT_PUMP: 'Varmepumpe',
  HEAT_PUMP_INFORMATION_SUPPLEMENTARY: 'Supplerende Varmepumpeoplysninger',
  HEIGHT: 'Højde',
  HOT_WATER_TANK: 'Varmtvandsbeholder',
  HOUR: 'time',
  HOURS: 'timer',
  HOT_WATER_COP: 'COP (varmt vand)',
  HOW_MUCH_DO_YOU_CHARGE_AT_HOME: 'Hvor meget lader du derhjemme?',
  IMAGES: 'Billeder',
  INCLUDE_NORLYS_PROMOTION_IN_EMAIL: 'Inkluder samarbejde med Norlys i email',
  INPUT_OFFER_TAG: 'Indtast tilbuds ID',
  INSIDE_PART: 'Indedel',
  INSTALLATION: 'Installation',
  INSTALLATION_GUIDE: 'Installationsguide',
  INSTALLER: 'Installatør',
  INSTALLER_LINK: 'Installatør link',
  INVERTER: 'Inverter',
  INFLATION: 'Inflation',
  I_AM_HOME_EVERY_DAY: 'Jeg er hjemme hver dag',
  I_AM_HOME_IN_THE_MORNING: 'Jeg er hjemme om morgenen, og kommer hjem om eftermiddagen',
  I_AM_HOME_MOST_DAYS: 'Jeg er hjemme de fleste dage',
  I_COME_HOME_AT_17: 'Jeg er afsted om dagen og kommer hjem omkring kl. 17',
  I_COME_HOME_IN_THE_AFTERNOON: 'Jeg er afsted om dagen og kommer hjem om eftermiddagen',
  INCLINED_DEPTH: 'Skrå dybde (medregnet højde)',
  HEAT_PUMP_SUBSIDY: 'Varmepumpe tilskud',
  JANUARY: 'Januar',
  JULY: 'Juli',
  JUNE: 'Juni',
  LANDING_PAGE_SUBTITLE: 'Vælg et system for at forsætte',
  LAST_NAME: 'Efternavn',
  LATEST_SENT_OFFER: 'Senest afsendte tilbud',
  LEAD_ORIGIN: 'Lead oprindelse',
  LENGTH: 'Længde',
  LIVING_AREA: 'Boligareal',
  LOGIN_ERROR: `${unexpectedError}. Verificer at du har indtastet email og password korrekt.`,
  LOGIN_GOOGLE_BUTTON: 'Log ind via Google',
  LOGIN_RESET_CODE_DESCRIPTION:
    'Indtast din email adresse nedenfor. Vi sender dig en midlertidig kode, som du kan bruge til at ændre din adgangskode.',
  LOGIN_RESET_CODE_ERROR: `${unexpectedError}. Verificer at email adressen er indtastet korrekt.`,
  LOGIN_RESET_PASSWORD_DESCRIPTION:
    'Brug den midlertidige kode, som vi har sendt til dig i en email, til at opdatere din adgangskode.',
  LOGIN_RESET_PASSWORD_ERROR: `${unexpectedError}. Verificer at den modtagede kode er indtastet korrekt.`,
  LOGIN_SEND_CODE: 'Send midlertidig kode',
  LOGIN_TEMPORARY_CODE: 'Midlertidig kode',
  LOGIN_UPDATE_PASSWORD: 'Opdater adgangskode',
  LOG_IN: 'Log ind',
  LOG_OUT: 'Log ud',
  M3_LOOSE_WOOD: 'm3 løst træ',
  M3_STACKED_WOOD: 'm3 stablet træ',
  MARCH: 'Marts',
  MARGIN: 'Dækningsbidrag',
  MARK_AS_COMPLETED: 'Installatør betalt',
  MARK_AS_PAID: 'Betaling modtaget fra kunde',
  MARK_OFFER_AS_SENT: 'Marker tilbud som sendt',
  MAXIMUM: 'Maksimal',
  MAXIMUM_NUMBER_OF_PANELS: 'Maksimum antal paneler',
  MAXIMUM_PRODUCTION: 'Maksimal produktion',
  MAY: 'Maj',
  MEDIUM: 'Medium',
  MESSAGE_FOR_CUSTOMER: 'Besked til kunde',
  MISCELLANEOUS_FILES: 'Diverse filer',
  MONTHLY_SAVINGS: 'Sparet per måned',
  MONTHLY_LOAN_REPAYMENT: 'Månedlig ydelse på lån',
  MONTHLY_PROFIT: 'Overskud per måned',
  MY_PAGE: 'Min Side',
  NAVIGATE_TO_SKRAAFOTO_AND_COPY_ADDRESS: 'Naviger til skråfoto og kopiere address till clipboard',
  NET_EXPENSES: 'Netto omkostninger',
  NEW_PASSWORD: 'Ny adgangskode',
  NEW_USER: 'Ny bruger',
  NO: 'Nej',
  NO_BBR_DATA_TO_FETCH: 'Intet BBR-data at hente',
  NONE: 'Ingen',
  NORLYS_PROMOTION_TOOLTIP:
    'Inkluder følgende tekst på emailen: "PS: Hos Bodil samarbejder vi med Norlys om at gøre Danmark grønnere. Tegn en elaftale hos Norlys og få hjælp til at søge om reduceret elafgift, så du kan skære toppen af din elregning. Samtidig sparer du 500 kr. på din første elregning."',
  NOT_FOUND: 'Ikke fundet',
  NOT_HEATED_AREA: 'Ikke opvarmet boligareal',
  NOTE_AUTOMATIC_SIGNATURE: 'Bemærk, at der tilføjes en automatisk signatur til denne e-mail',
  NOTE_THAT_THIS_IS_ADDED_TO_CONSUMPTION: 'Bemærk, at dette tillægges boligens forbrug',
  NOTE: 'Note',
  NOTES: 'Noter',
  NOVEMBER: 'November',
  NO_CUSTOMER: 'Ingen kunde',
  NO_ELECTRIC_CAR: 'Ingen elbil',
  NO_OFFER_WITH_TAG: 'Intet tilbud med det valgte ID',
  NO_RESIDENCE: 'Ingen bolig',
  NO_ROOF_PLANE: 'Ingen Tagflader',
  NO_SHADE: 'Ingen skygger',
  NUMBER_OF_FLOORS: 'Antal etager',
  NUMBER_OF_PANELS: 'Antal paneler',
  NUMBER_OF_RESIDENTS: 'Antal beboere',
  OCTOBER: 'Oktober',
  OFFER: 'Tilbud',
  OFFERS_CREATED: 'Tilbud oprettet',
  OFFERS_NOT_CREATED_WARNING: 'Tilbud ikke oprettet',
  OFFERS_SENT: 'Tilbud afsendt',
  OFFER_CREATED: 'Tilbud oprettet',
  OFFER_CREATED_BY: 'Tilbud oprettet af',
  OFFER_HAS_DISABLED_PRODUCTS: 'Dette tilbud har produkter, der er slået fra. Vælg et andet tilbud.',
  OFFER_ID: 'Tilbuds ID',
  OFFER_NOTES: 'Tilbudsnoter',
  OIL_BOILER: 'Oliefyr',
  ONLY_OUTDOOR_UNIT: 'Kun udedel',
  OPEN_ON_INSTALLATION_PAGE: 'Åben på installationssiden',
  OPEN_ON_SITE_VISIT_PAGE: 'Åben på kundebesøgssiden',
  OR: 'Eller',
  OTHER: 'Andet',
  OTHER_EXPENSES: 'Andre produkter',
  OUTSIDE_PART: 'Udedel',
  OVERVIEW_PAGE: 'Oversigt',
  OVERVIEW_PAGE_FILTER_CUSTOMER: 'Filtreret på kunder',
  OVERVIEW_PAGE_FILTER_OFFER: 'Filtreret på tilbud',
  PAGE_ERROR: 'Intern fejl',
  PAGE_NOT_FOUND: 'Side ikke fundet',
  PAID: 'Betalt',
  PALLET_WOOD: 'Palletræ (uspecificeret)',
  PARTIALLY_HEATED_AREA: 'Delvist opvarmet boligareal',
  PARTIALLY_SHADY: 'Delvist skygger',
  PAYBACK_TIME: 'Tilbagebetalningstid',
  PAY_INSTALLER: 'Betal installatør',
  PENDING_INSTALLER: 'Afventer installatør',
  PERCENTAGE_FLOOR_HEATING: '%GV',
  PERCENTAGE_OF_ROOF_DEDICATED_TO_SOLAR_PANELS: 'Procent af taget egnet til solpaneler',
  PERFORM_INSTALLATION: 'Foretag installation',
  PERFORM_SITE_VISIT: 'Foretag kundebesøg',
  PHONE: 'Telefon',
  PHONE_NUMBER: 'Telefonnummer',
  PIECE_DENOMINATOR: 'stk.',
  PILL_FIRE: 'Pillefyr',
  PLASTIC: 'Plastik',
  POSSIBLE_NUMBER_OF_PANELS: 'Antal mulige paneler',
  PREFERRED: 'Foretrukket',
  PRELIMINARY_OFFER: 'Indledende tilbud',
  PRELIMINARY_OFFER_ACCEPTED: 'Indledende tilbud accepteret',
  PRELIMINARY_OFFER_CREATED: 'Indledende tilbud oprettet',
  PRELIMINARY_OFFER_SENT: 'Indledende tilbud afsendt',
  PRICE: 'Pris',
  PRICE_FOR_CUSTOMER: 'Kundens pris',
  PRICE_OF: 'Prisen på',
  PRICE_PER_YEAR: 'Pris pr. år',
  PRICE_WITH_VAT: 'Pris med moms',
  PRIMARY_HEATING: 'Primær opvarmning',
  PRODUCT: 'Produkt',
  PRODUCTION_CAPACITY: 'Produktionskapacitet',
  PRODUCTS: 'Produkter',
  PRODUCTS_NOT_SELECTED: 'Produkter ikke valgt',
  PRODUCTS_SAVED: 'Produkt gemt',
  PRODUCT_TYPE: 'Produkttype',
  PROFIT: 'Gevinst',
  PURCHASE_HEAT_PUMP_AT: 'Køb varmepumpen hos',
  QUANTITY: 'Antal',
  RADIATOR: 'Radiator',
  REAL_PRODUCTION: 'Reel produktion',
  RECOMMENDED_AT_MINUS_12_DEGREES: 'Anbefalet ved -12º',
  RECOMMENDED_AT_MINUS_7_DEGREES: 'Anbefalet ved -7º',
  RECOMMENDED_HEAT_EFFECTS: 'Anbefalede varmeeffekter',
  REMAINING_ELECTRICITY_SOLD: 'Resterende strøm solgt',
  REMOVE: 'Fjern',
  RENOVATION_DEGREE: 'Renovationsgrad',
  RENOVATION_YEAR: 'Renovations år',
  REPORT_BUG_OR_FEATURE: 'Anmod om funktionalitet / Anmeld fejl',
  REQUISITION_NUMBER: 'Rekvisitionsnummer',
  RERENDER_PDF: 'Geninlæs PDF',
  RESERVATION: 'Forbehold',
  RESET: 'Nulstil',
  RESIDENCE_INFORMATION: 'Boligdata',
  RESIDENCE_ROOF: 'Boligens tag',
  RESIDENCE_TYPE: 'Boligtype',
  ROOF_PLANE: 'Tagflade',
  ROOF_SLOPE_IN_DEGREES: 'Taghældning i grader',
  ROOF_TYPE: 'Tagtype',
  SAVE: 'Gem',
  SAVED: 'Gemt',
  SAVINGS: 'Besparelse',
  SEARCH_FOR_ADDRESS: 'Søg på adresse',
  SEARCH_FOR_CUSTOMER: 'Søg efter kunde',
  SEARCH_FOR_CRM_CUSTOMER: `Søg efter kunde i ${global.CURRENT_CRM}`,
  SECONDARY_HEATING: 'Sekundær opvarming',
  SELECT: 'Vælg',
  SELECTED: 'Valgt',
  SELECTED_CUSTOMER: 'Valgt kunde',
  SELECT_CUSTOMER: 'Vælg kunde',
  SELECT_OFFER: 'Vælg tilbud',
  SELECT_PRODUCTS: 'Vælg produkter',
  SELECT_RESIDENCE: 'Vælg bolig',
  SELF_USAGE: 'Egenudnyttelse',
  SEND: 'Send',
  SEND_CHOSEN_OFFERS: 'Send valgte tilbud',
  SEND_FINAL_OFFER: 'Send endeligt tilbud',
  SEND_INVOICE: 'Send faktura',
  SEND_OFFER: 'Send tilbud',
  SEND_PRELIMINARY_OFFER: 'Send indledende tilbud',
  SEPTEMBER: 'September',
  SHOWN_IN_PDF: 'Vist i PDF',
  SHOW_ARCHIVED: 'Vis arkiverede',
  SITE_VISIT: 'Kundebesøg',
  SITE_VISIT_ADD_EXPENSE: 'Tilføj postering',
  SITE_VISIT_ANSWER_EXISTING_PANEL: 'Eksisterende eltavle',
  SITE_VISIT_ANSWER_NOISE_LIMIT_WITH_SCREEN: 'Ja, med støjskærm',
  SITE_VISIT_ANSWER_NOISE_LIMIT_NOTE: 'Varmepumpen overholder ikke støjgrænse - Skift til en mere lydsvag varmepumpe',
  SITE_VISIT_ANSWER_ROOF_TYPE_LER_TEGL: 'Lertegl',
  SITE_VISIT_ANSWER_ROOF_TYPE_BETON_TEGL: 'Betontegl',
  SITE_VISIT_ANSWER_ROOF_TYPE_METAL: 'Metal eller ståltag',
  SITE_VISIT_ANSWER_ROOF_TYPE_ETERNIT: 'Bølgeeternit eller fibercement',
  SITE_VISIT_ANSWER_ROOF_TYPE_FLAT: 'Fladt tag',
  SITE_VISIT_ANSWER_ROOF_TYPE_TAGPAP: 'Tagpap',
  SITE_VISIT_ANSWER_SOLAR_CABLE_THROUGH_ROOF: 'Igennem taget',
  SITE_VISIT_ANSWER_SOLAR_CABLE_OUTSIDE_HOUSE: 'Udenpå huset',
  SITE_VISIT_ANSWER_STRINGS_1: '1-strengs',
  SITE_VISIT_ANSWER_STRINGS_2: '2-strengs',
  SITE_VISIT_ANSWER_UPGRADE_PANEL: 'Udvidelse af eltavle',
  SITE_VISIT_CONDITION: 'Forbehold',
  SITE_VISIT_CONTROL_DISCLAIMER:
    'Bodil Energi tager ansvaret for dimensioneringen af varmepumpen, såfremt der kan svares “ja” til de følgende 4 spørgsmål',
  SITE_VISIT_DISCLAIMER_CONTROL_QUESTIONS: 'Kunden har oplyst ændret forbrug eller opvarmet areal',
  SITE_VISIT_DISCLAIMER_BYPASS_VALVES: 'Etablering af bypass ventil',
  SITE_VISIT_DISCLAIMER_CIRCULATION_PUMP: 'Ny cirkulationspumpe',
  SITE_VISIT_DISCLAIMER_DIG_DOWN_PIPES: 'Gravearbejde ifbm nedgraving af rør til udedel',
  SITE_VISIT_DISCLAIMER_EXTRA_CABLE: 'Ekstra kabellængde (hvis mere end 5m er nædvendigt)',
  SITE_VISIT_DISCLAIMER_EXTRA_CABLE_SOLAR: 'Ekstra kabellængde (hvis mere end 10m er nædvendigt)',
  SITE_VISIT_DISCLAIMER_EXTRA_PIPE: 'Ekstra rørlængde (hvis mere end 5m er nødvendigt)',
  SITE_VISIT_DISCLAIMER_EXTRA_SHUNT: 'Ekstra shunt',
  SITE_VISIT_DISCLAIMER_ROOF_FLAT: 'Beslag til fladt tag',
  SITE_VISIT_DISCLAIMER_ROOF_OTHER: 'Tilføj uddybende kommentar angående tagtypen',
  SITE_VISIT_DISCLAIMER_FOUNDATION: 'Etablering af underlag',
  SITE_VISIT_DISCLAIMER_FURNACE_DISMANTLE: 'XL demontering af eksisterende fyr',
  SITE_VISIT_DISCLAIMER_FURNACE_RADIATOR: 'Ny radiator i fyrrum (husk at noter størrelse og placering)',
  SITE_VISIT_DISCLAIMER_HEAT_PUMP_ENTRY: 'Skift varmepumpe til mindre indedel (fx hydrobox)',
  SITE_VISIT_DISCLAIMER_MAKE_ROOM: 'Kunden skal sørge for at lave plads til aflæsning af materialer',
  SITE_VISIT_DISCLAIMER_NOISE_LIMIT: 'Bodil tager forbehold for at kunden selv etablerer støjskærm for varmpumpen',
  SITE_VISIT_DISCLAIMER_PIPE_SIZE: 'Overvej om varmepumpe har høj nok fremløbstemperatur',
  SITE_VISIT_DISCLAIMER_REPLACE_RADIATOR: 'Ny radiator (husk at noter størrelse og placering)',
  SITE_VISIT_DISCLAIMER_REQUIRED_BUFFER: 'Bekræft at der er buffertank i tilbuddet',
  SITE_VISIT_DISCLAIMER_REQUIRED_NO_BUFFER: 'Bekræft at der ikke er buffertank i tilbuddet',
  SITE_VISIT_DISCLAIMER_SCAFFOLDING: 'Tilføj kommentar om, hvad der skal gøres med stillads',
  SITE_VISIT_DISCLAIMER_SOLAR_CABLE_PICTURES: 'Husk at tilføj billed af den tænkte kabelføring',
  SITE_VISIT_DISCLAIMER_SYSTEM_STRINGS:
    'Kunden har et 1-strengs anlæg, overvej om varmepumpe har høj nok fremløbstemperatur, eller om den bør udskiftes',
  SITE_VISIT_DISCLAIMER_TWO_PIPE_SYSTEM: 'Bekræft at den valgte varmepumpe kan lave 2 kredssystem',
  SITE_VISIT_DISCLAIMER_UNDER_ROOF: 'Tilføj uddybende kommentar omkring typen af undertag',
  SITE_VISIT_DISCLAIMER_UPGRADE_RELAY: 'Udvidelse af eltavle',
  SITE_VISIT_FOUNDATION_HARD: 'Mere end 50% hårdt underlag',
  SITE_VISIT_FOUNDATION_SOFT: 'Mindre end 50% hårdt underlag',
  SITE_VISIT_IMAGES: 'Kundebesøgbilder',
  SITE_VISIT_INSTALLER_LINK: 'Kundebesøg link',
  SITE_VISIT_NO_NOISE_SCREEN: 'Uden støjskærm',
  SITE_VISIT_QUESTION_BYPASS_VALVES: 'Er der bypass ventiler?',
  SITE_VISIT_QUESTION_CHANGE_CIRCULATION_PUMP: 'Skal cirulationspumpen udskiftes?',
  SITE_VISIT_QUESTION_DIG_DOWN_PIPES: 'Skal der graves rør ned i jorden?',
  SITE_VISIT_QUESTION_ELECTRICITY_PANEL_DISTANCE: 'Hvor mange meter er der fra eltavle til indedel?',
  SITE_VISIT_QUESTION_ELECTRICITY_PANEL_UPGRADE:
    'Skal varmepumpen tilsluttes i eksisterende eltavle eller skal der laves en udvidelse?',
  SITE_VISIT_QUESTION_ELECTRICITY_WIRE_OVER_CEILING: 'Skal ledninger til varmepumpen trækkes over loftet?',
  SITE_VISIT_QUESTION_FLOOR_HEATING_EXTRA_SHUNT: 'Skal der bruges en ekstra shunt til gulvarme?',
  SITE_VISIT_QUESTION_FLOOR_PLAN_ELECTRICITY_PANEL: 'Er eltavlen markeret på plantegning?',
  SITE_VISIT_QUESTION_FLOOR_PLAN_INDOOR_UNIT: 'Er placeringen af indedelen markeret på plantegning?',
  SITE_VISIT_QUESTION_FLOOR_PLAN_OUTDOOR_UNIT: 'Er placeringen af udedelen markeret på plantegning?',
  SITE_VISIT_QUESTION_FLOOR_PLAN_PIPING: 'Er rørføringen mellem varmepumpens inde og udedel markeret på plantegningen?',
  SITE_VISIT_QUESTION_FURNACE_DISMANTLE: 'Kan det eksisterende fyr (nemt) afmonteres og fjernes?',
  SITE_VISIT_QUESTION_HEAT_SYSTEM: 'Er varmeanlæget 1-strenget eller 2-strenget?',
  SITE_VISIT_QUESTION_HEAT_SYSTEM_BYPASS_VALVES: 'Er der bypass ventiler?',
  SITE_VISIT_QUESTION_INDOOR_CONDENSATION_PUMP: 'Er der en løsning til kondensvand fra indedelen?',
  SITE_VISIT_QUESTION_INDOOR_ENTRANCE_SIZE: 'Kan den valge varmepumpe komme ind i bygningen?',
  SITE_VISIT_QUESTION_INDOOR_NEED_BUFFER_TANK: 'Er der behov for buffertank?',
  SITE_VISIT_QUESTION_INDOOR_PIPE_AMOUNT: 'Hvor mange meter rør skal der bruges fra indedel til udedel?',
  SITE_VISIT_QUESTION_INDOOR_PIPE_LOCATION: 'Hvor skal rør trækkes til udedel?',
  SITE_VISIT_QUESTION_INDOOR_ROOM_ALTERNATIVE: 'Hvis nej, hvor meget plads er der eller alternativt bud på indedel?',
  SITE_VISIT_QUESTION_INDOOR_ROOM_FOR_WORK: 'Er der plads nok til det valgte anlæg og at udføre arbejdet?',
  SITE_VISIT_QUESTION_NOISE_LIMIT_OBSERVED: 'Overholdes støjgrænsen til nabo?',
  SITE_VISIT_QUESTION_PIPE_DIMENSIONS: 'Hvor store er rørdimensionerne til og fra radiator?',
  SITE_VISIT_QUESTION_PIPING_MATERIAL: 'Hvilket materiale er røret?',
  SITE_VISIT_QUESTION_PIPING_TWO_SYSTEMS: 'Skal systemet laves med 2 kredse?',
  SITE_VISIT_QUESTION_PROVIDER_FOR_FOUNDATION: 'Hvem sørger for stabilt underlag til varmpumpen?',
  SITE_VISIT_QUESTION_REPLACE_FURNACE_WITH_RADIATOR:
    'Skal der opsættes en radiator i fyrrum for at kompensation af fyrets varmetab?',
  SITE_VISIT_QUESTION_REPLACE_RADIATORS: 'Skal der skiftes radiator?',
  SITE_VISIT_QUESTION_ROOF_LIVING_SPACE: 'Er der loftrum, eller loft til kip?',
  SITE_VISIT_QUESTION_ROOF_TYPE: 'Hvilken tagtype er taget?',
  SITE_VISIT_QUESTION_ROOF_UNDER_ROOF: 'Er der undertag?',
  SITE_VISIT_QUESTION_ROOM_FOR_MATERIALS: 'Er der plads til aflæsning af materialer til varmepumpe m.m.?',
  SITE_VISIT_QUESTION_SOLAR_CABLES_ON_FLOOR_PLAN:
    'Er kapelføringen mellem solcellepanelerne og inverteren markeret på plantegningen?',
  SITE_VISIT_QUESTION_SOLAR_CABLE_LOCATION: 'Hvor skal kabler trækkes?',
  SITE_VISIT_QUESTION_SOLAR_ELECTRICITY_RELAY:
    'Er der plads til automatsikring og kombirelæ (3 pladser) i eltavlen, eller skal der laves en udvidelse?',
  SITE_VISIT_QUESTION_SOLAR_ELECTRICITY_WIRE_LENGTH: 'Hvor mange meter er der fra eltavle til solceller?',
  SITE_VISIT_QUESTION_SOLAR_ELECTRICITY_WIRE_PLACEMENT: 'Skal ledninger til solcellerne trækkes over loftet?',
  SITE_VISIT_QUESTION_SOLAR_FOUNDATION_PROVIDER: 'Hvem sørger for stabilt underlag til solcellerne?',
  SITE_VISIT_QUESTION_SOLAR_INVERTER_BATTERY_ON_FLOOR_PLAN:
    'Er placeringen af inverter og batteri markeret på plantegningen?',
  SITE_VISIT_QUESTION_SOLAR_MATERIALS: 'Er der plads til aflæsning af materialer til solceller m.m.?',
  SITE_VISIT_QUESTION_SOLAR_ON_FLOOR_PLAN: 'Er placeringen af solceller markeret på plantegningen?',
  SITE_VISIT_QUESTION_SOLAR_SCAFFOLDING: 'Er det let tilgængeligt at komme til med stillads?',
  SITE_VISIT_SECTION_FURNACE: 'Fyr',
  SITE_VISIT_SECTION_INDOOR: 'Indedel',
  SITE_VISIT_SECTION_PIPING: 'Rørføring',
  SITE_VISIT_SECTION_RADIATOR: 'Varmeafgiver og radiatorer',
  SITE_VISIT_SECTION_ROOF: 'Tagkonstruktion',
  SITE_VISIT_SECTION_SOLAR: 'Placering af solceller',
  SITE_VISIT_SECTION_SOLAR_ELECTRICITY: 'Eltavle',
  SITE_VISIT_SECTION_SOLAR_MISC_PLACEMENT: 'Placering af inverter og batteri',
  SITE_VISIT_SUMMARY: 'Opsummering af kundebesøg',
  SITE_VISIT_WITH_NOISE_SCREEN: 'Med støjskærm',
  SOLAR_CELL: 'Solcelle',
  SOLAR_CELL_INSTALLATION: 'Solcelle anlæg',
  SOLAR_CELL_INTENSITY: 'Solcelleintensitet',
  SOLAR_PACKAGE_COMPARISON: 'Sammenligning af solcellepakker',
  SOLAR_PANELS: 'Solpaneler',
  SOLD_ELECTRICITY: 'Salg af strøm',
  SOUND_LEVEL: 'Lydniveau',
  SOUTH: 'Syd',
  SOUTH_EAST: 'SØ',
  SOUTH_WEST: 'SV',
  STANDARD: 'Standard',
  STATISTIC_ACCORDING_TO_BUILT_YEAR: 'Statistik ifølge byggeår',
  STATISTIC_ACCORDING_TO_ENERGY_LABEL: 'Statistik ifølge energimærke',
  STEEL: 'Stål',
  STRAW_FIRE: 'Halmfyr',
  SUBSCRIPTION: 'Abonnement',
  SUBSIDY: 'Tilskud',
  SUMMER_HOUSE: 'Fritidshus',
  SUPPLEMENTARY_HEATING: 'Supplerende opvarming',
  SUPPORT: 'Support',
  SYNCHRONIZE_CONTACT_WITH_CRM: `Synkronisér kontakt med ${global.CURRENT_CRM}`,
  SYNCHRONIZE_OFFER_WITH_CRM: `Synkronisér tilbud med ${global.CURRENT_CRM}`,
  TERRACED_HOUSE: 'Rækkehus',
  TILED_STOVE: 'Kakkelovn',
  TIME_SINCE_OFFER_RECEIVED_THIS_STATUS: 'Tid siden tilbuddet fik denne status',
  TITLE: 'Titel',
  TONNE: 'Ton',
  TOTAL_AREA: 'Samlet areal',
  TOTAL_ELECTRICITY_USAGE: 'Elforbrug totalt',
  TOTAL_WEIGHT: 'Samlet vægt',
  TYPE: 'Type',
  UNKNOWN: 'Ukendt',
  UNIT: 'Enhed',
  UNIT_PRICE: 'Enhedspris',
  UPDATE_CUSTOMER_AND_CONTINUE: 'Opdater kunde og forsæt',
  UPDATE_RESIDENCE: 'Opdater bolig',
  VERY_SHADY: 'Mange skygger',
  VIEW_PDF: 'Se PDF',
  VILLA: 'Villa',
  WALL: 'Væg',
  WEIGHT: 'Vægt',
  WELCOME_TO_BODIL: 'Velkommen til Bodil',
  WEST: 'Vest',
  WIDTH: 'Bredde',
  WITH: 'Med',
  WITHOUT: 'Uden',
  WITHOUT_FEES: 'Uden gebyrer',
  WOOD_STOVE: 'Brændeovn',
  YEAR: 'År',
  YEARLY: '/ År',
  YEARLY_CO2_SAVINGS: 'Årlig CO2 besparelse',
  YEARLY_ELECTRICITY_CONSUMPTION: 'Årligt elforbrug',
  YEARLY_PRODUCTION_AND_USAGE_INFORMATION: 'Årlig produktions- og brugsoplysninger',
  YEARLY_SAVINGS: 'Årlig Besparelse',
  YEARS: 'År',
  YES: 'Ja',
};
