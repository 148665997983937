import { useEffect } from 'react';

import { Card } from '~src/components/display';
import { TagsOutlined } from '~src/components/display/Icons';
import { InfoBox } from '~src/components/display/InfoBox';
import { OfferProfitMargins } from '~src/components/display/OfferProfitMargins';
import { CustomerNotes } from '~src/components/features/shared/customer';
import { OfferExpenseTableNewOffer } from '~src/components/features/shared/offer/OfferExpenseTable';
import { AssortedProductSelector } from '~src/components/features/shared/product';
import { useLocalization, useOfferProductState } from '~src/hooks';
import { OfferProducts } from '~src/types';
import { calculateOfferPrice, formatCurrency, withVAT } from '~src/utilities/calculationHelper';
import { offerPriceWithoutFees } from '~src/utilities/offer';

import { useCombineOfferProductsWithExpenses } from '../../hooks/useCombineOfferProductsWithExpenses';
import { useSynchronizeStatefulCustomerNotes } from '../../hooks/useSynchronizeStatefulCustomerNotes';
import { useCreateOfferState } from '../../state/createOfferState';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

import { tab } from './product.module.css';

export const CreateOfferAssortedProductTab = () => {
  const translate = useLocalization();

  const { customer, offerList } = useCreateOfferState();
  useSynchronizeStatefulCustomerNotes();

  const { selectedAssortedProducts, setOfferExpenses, selectedOfferExpenses } = useOfferProductState();

  useEffect(
    function removeExistingExpenses() {
      setOfferExpenses([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offerList.length]
  );

  const offerProducts: OfferProducts = {
    assortedProducts: Object.values(selectedAssortedProducts ?? {}),
  };

  const offerProductsWithExpenses = useCombineOfferProductsWithExpenses(offerProducts);

  const price = withVAT(calculateOfferPrice(offerProducts));
  const priceWithoutFees = offerPriceWithoutFees(price, offerProducts);

  return (
    <Card className={tab}>
      <div className={displayRow}>
        <InfoBox
          title={translate.PRICE}
          icon={<TagsOutlined />}
          value={formatCurrency(price ?? 0, { decimals: 0 })}
          subtitle={`(${translate.WITHOUT_FEES}: ${formatCurrency(priceWithoutFees, { decimals: 0 })})`}
        />
        <OfferProfitMargins offer={offerProductsWithExpenses} />

        <div className={displayColumn}>
          <CustomerNotes customer={customer} rows={12} />
        </div>
      </div>

      <AssortedProductSelector />

      <br />
      <OfferExpenseTableNewOffer
        expenses={selectedOfferExpenses}
        offerType='assorted'
        tableTitle={translate.EXPENSE}
        updateExpenses={setOfferExpenses}
      />
    </Card>
  );
};
