import { findOfferValidProductType } from '@bodilenergy/domain';

import { Card, Divider } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { Offer, Residence } from '~src/types';

import { useQuestionInformation } from './hooks/useQuestionInformation';
import { QuestionSectionControl } from './QuestionSectionControl';
import { QuestionSectionElectricity } from './QuestionSectionElectricity';
import { QuestionSectionElectricitySolar } from './QuestionSectionElectricitySolar';
import { QuestionSectionFloorPlan } from './QuestionSectionFloorPlan';
import { QuestionSectionFloorPlanSolar } from './QuestionSectionFloorPlanSolar';
import { QuestionSectionHeatSource } from './QuestionSectionHeatSource';
import { QuestionSectionIndoor } from './QuestionSectionIndoor';
import { QuestionSectionInverterBattery } from './QuestionSectionInverterBattery';
import { QuestionSectionOutdoor } from './QuestionSectionOutdoor';
import { QuestionSectionPiping } from './QuestionSectionPiping';
import { QuestionSectionRadiators } from './QuestionSectionRadiators';
import { QuestionSectionRoof } from './QuestionSectionRoof';
import { QuestionSectionSolar } from './QuestionSectionSolar';

import { questionSection } from './QuestionInformation.module.css';

type QuestionInformationProps = {
  offer?: Pick<Offer, 'heatPumpOutdoorUnit' | 'solarPanel' | 'assortedProducts'>;
  residence?: Residence;
};

export const QuestionInformation = ({ residence, offer }: QuestionInformationProps) => {
  const translate = useLocalization();

  const offerType = findOfferValidProductType(offer);

  return (
    <Card className={questionSection} title={translate.SITE_VISIT}>
      {offerType === 'heatPump' && <QuestionsHeatPump offer={offer} residence={residence} />}

      {offerType === 'solar' && <QuestionsSolar residence={residence} />}

      {offerType === 'assorted' && (
        <>
          <QuestionsHeatPump offer={offer} residence={residence} />

          <QuestionsSolar residence={residence} />
        </>
      )}
    </Card>
  );
};

const QuestionsHeatPump = ({ offer, residence }: QuestionInformationProps) => {
  const {
    answerMap,
    imageMap,
    isLoading,
    handleUpdate,
    handleUploadImages,
    isUploadingImages,
    secondaryHeatSource,
    isLoadingChecklist,
  } = useQuestionInformation(residence);

  if (isLoadingChecklist) {
    return null;
  }

  return (
    <>
      <QuestionSectionOutdoor
        answerMap={answerMap}
        heatPumpOutdoorUnit={offer?.heatPumpOutdoorUnit}
        imageList={imageMap.outdoorPlacement}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
      <Divider fullWidth />

      <QuestionSectionControl
        answerMap={answerMap}
        isLoading={isLoading}
        residence={residence}
        secondaryHeatSource={secondaryHeatSource}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
      <Divider fullWidth />

      <QuestionSectionRadiators
        answerMap={answerMap}
        imageList={imageMap.radiators}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
      <Divider fullWidth />

      <QuestionSectionHeatSource
        answerMap={answerMap}
        imageList={imageMap.heatSource}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
      <Divider fullWidth />

      <QuestionSectionPiping
        answerMap={answerMap}
        heatingIsMix={residence?.heatDistribution === 'radiatorAndFloor'}
        imageList={imageMap.piping}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
      <Divider fullWidth />

      <QuestionSectionIndoor
        answerMap={answerMap}
        imageList={imageMap.indoorPlacement}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
      <Divider fullWidth />

      <QuestionSectionElectricity
        answerMap={answerMap}
        imageList={imageMap.electricity}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
      <Divider fullWidth />

      <QuestionSectionFloorPlan
        imageList={imageMap.floorPlan}
        answerMap={answerMap}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
    </>
  );
};

const QuestionsSolar = ({ residence }: Pick<QuestionInformationProps, 'residence'>) => {
  const { answerMap, imageMap, isLoading, handleUpdate, handleUploadImages, isUploadingImages, isLoadingChecklist } =
    useQuestionInformation(residence);

  if (isLoadingChecklist) {
    return null;
  }

  return (
    <>
      <QuestionSectionSolar
        imageList={imageMap.solarPlacement}
        answerMap={answerMap}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
      <Divider fullWidth />

      <QuestionSectionInverterBattery
        answerMap={answerMap}
        imageList={imageMap.inverterAndBatteryPlacement}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
      <Divider fullWidth />

      <QuestionSectionRoof
        answerMap={answerMap}
        imageList={imageMap.roofConstruction}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
        residence={residence}
      />
      <Divider fullWidth />

      <QuestionSectionElectricitySolar
        answerMap={answerMap}
        imageList={imageMap.electricity}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
      <Divider fullWidth />

      <QuestionSectionFloorPlanSolar
        answerMap={answerMap}
        imageList={imageMap.floorPlan}
        isLoading={isLoading}
        updateAnswer={handleUpdate}
        uploadImages={handleUploadImages}
        isUploadingImages={isUploadingImages}
      />
    </>
  );
};
