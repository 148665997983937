import uniqBy from 'lodash/uniqBy';

import { OfferInformationCard } from '~src/components/display';
import { TagsOutlined } from '~src/components/display/Icons';
import { InfoBox } from '~src/components/display/InfoBox';
import { OfferProfitMargins } from '~src/components/display/OfferProfitMargins';
import { DefaultLayout } from '~src/components/layouts';
import { useLocalization } from '~src/hooks';
import { useDeleteOfferExpenses, useSetOfferExpenses, useUpdateOfferProductPrice } from '~src/hooks/services';
import {
  Customer,
  mapCustomerToCustomerInput,
  mapResidenceToResidenceInput,
  Offer,
  OfferExpenseInput,
  OfferProducts,
  OfferProductType,
  Residence,
  toOfferExpenseInput,
} from '~src/types';
import {
  calculateOfferPrice,
  findOfferValidProductType,
  formatCurrency,
  withVAT,
} from '~src/utilities/calculationHelper';
import { divideOfferExpenses, offerPriceWithoutFees } from '~src/utilities/offer';

import { useCombineOfferProductsWithExpenses } from '../createOffer/hooks/useCombineOfferProductsWithExpenses';
import { CustomerNotes } from '../shared/customer';
import { OfferExpenseTableExistingOffer } from '../shared/offer/OfferExpenseTable';
import { OfferProductTable } from '../shared/offer/OfferProductTable';
import { OfferInstallerLinks } from './OfferInstallerLinks';
import { OfferNotes } from './OfferNotes';
import { OfferPageHeader } from './OfferPageHeader';
import { OfferReservations } from './OfferReservations';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

import { assortedOfferPageContent, noteContainer } from './offerPageContent.module.css';

type AssortedOfferPageContentProps = {
  offer?: Omit<Offer, 'customer' | 'residence'> & OfferProducts;
  residence?: Residence;
  customer?: Customer;
};

export const AssortedOfferPageContent = ({ offer, residence, customer }: AssortedOfferPageContentProps) => {
  const translate = useLocalization();

  const residenceInput = mapResidenceToResidenceInput(residence);
  const customerInput = mapCustomerToCustomerInput(customer);

  const { setOfferExpenses, isLoading: isUpdatingExpenses } = useSetOfferExpenses();
  const { deleteOfferExpenses, isLoading: isDeletingExpenses } = useDeleteOfferExpenses();
  const { updateOfferProductPrice, isLoading: isUpdatingProductPrices } = useUpdateOfferProductPrice();

  const offerType = findOfferValidProductType(offer);

  const offerProductsWithExpenses = useCombineOfferProductsWithExpenses(offer ?? {});

  const price = withVAT(calculateOfferPrice(offer));
  const priceWithoutFees = offerPriceWithoutFees(price, offer);

  if (!offerType || !offer?.id) {
    return null;
  }

  const updateOfferExpenses = async (expenses: OfferExpenseInput[]) => {
    if (!offer?.id) {
      return;
    }

    const expensesAsInput: OfferExpenseInput[] = (offer?.expenses ?? []).map(toOfferExpenseInput);
    const allExpenses = expenses.concat(expensesAsInput);

    await setOfferExpenses(
      offer.id,
      uniqBy(allExpenses, ({ templateID, title }) => `${templateID}-${title}`)
    );
  };

  const updateProductPrice = async (unitId: number, productPrice: number, type: OfferProductType) => {
    if (!offer?.id) {
      return;
    }

    await updateOfferProductPrice({ offerId: offer.id, unitId, price: productPrice, type });
  };

  const { added: addedExpenses, other: otherExpenses } = divideOfferExpenses(offer?.expenses);

  return (
    <DefaultLayout>
      <OfferPageHeader offer={offer} residence={residenceInput} />

      <div className={assortedOfferPageContent}>
        <div className={displayColumn}>
          <OfferInformationCard offer={offer} address={residence?.address} />

          <div className={displayRow}>
            <InfoBox
              title={translate.PRICE}
              icon={<TagsOutlined />}
              value={formatCurrency(price ?? 0, { decimals: 0 })}
              subtitle={`(${translate.WITHOUT_FEES}: ${formatCurrency(priceWithoutFees, { decimals: 0 })})`}
            />

            <OfferProfitMargins offer={offerProductsWithExpenses} />
          </div>

          <OfferInstallerLinks offer={offer} />

          <OfferProductTable
            offer={offer}
            updatePrice={updateProductPrice}
            title={translate.PRODUCTS}
            loading={isUpdatingProductPrices}
          />

          <OfferExpenseTableExistingOffer
            expenses={otherExpenses}
            offerType={offerType}
            updateExpenses={updateOfferExpenses}
            deleteExpenses={async ids => {
              deleteOfferExpenses(Number(offer.id))(ids);
            }}
            tableTitle={translate.EXPENSES}
            isLoading={isUpdatingExpenses || isDeletingExpenses}
            allowAdd={false}
          />

          <OfferExpenseTableExistingOffer
            expenses={addedExpenses}
            offerType={offerType}
            updateExpenses={updateOfferExpenses}
            deleteExpenses={async ids => {
              deleteOfferExpenses(Number(offer.id))(ids);
            }}
            tableTitle={translate.ADDED_EXPENSES}
            isLoading={isUpdatingExpenses || isDeletingExpenses}
          />

          <div className={noteContainer}>
            <OfferReservations offer={offer} />

            <OfferNotes offer={offer} />

            <CustomerNotes customer={customerInput} rows={10} title={translate.CUSTOMER_NOTES} />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
