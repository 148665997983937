import pick from 'lodash/pick';

import type {
  AssortedOfferInput as ApiAssortedOfferInput,
  Offer as ApiOffer,
  OfferExpense as ApiOfferExpense,
  OfferExpenseInput as ApiOfferExpenseInput,
  OfferExpenseTemplate,
  OfferInput as ApiOfferInput,
} from '~src/gql';

import { DeepNullToUndefined } from './util';

export type Offer = DeepNullToUndefined<ApiOffer>;
export type OfferInput = DeepNullToUndefined<ApiOfferInput>;

export type AssortedOfferInput = DeepNullToUndefined<ApiAssortedOfferInput>;

export type OfferExpense = DeepNullToUndefined<ApiOfferExpense>;
export type OfferExpenseInput = DeepNullToUndefined<ApiOfferExpenseInput>;

type Expense = DeepNullToUndefined<Partial<OfferExpenseTemplate> & Pick<OfferExpenseTemplate, 'price' | 'specialKey'>>;

export type NewOfferExpenses = {
  heatPump: Expense[];
  solar: Expense[];
  required: Expense[];
};

const OFFER_BASE_KEY_ARRAY = [
  'id',
  'chosenHeating',
  'reservations',
  'heatPumpOutdoorUnit',
  'heatPumpIndoorUnit',
  'solarPanel',
  'inverter',
  'battery',
  'assortedProducts',
] as const;

export const OFFER_BASE_KEYS: readonly (keyof Pick<Offer, (typeof OFFER_BASE_KEY_ARRAY)[number]>)[] =
  OFFER_BASE_KEY_ARRAY;

export type OfferInstallationExpense = DeepNullToUndefined<
  Pick<OfferExpenseTemplate, 'specialKey' | 'minimumProducts' | 'price' | 'buyPrice'>
>;

export const mapOfferToOfferInput = (
  offer?: Partial<Pick<Offer, (typeof OFFER_BASE_KEYS)[number]>> & {
    expenses?: OfferInput['expenses'];
  }
): OfferInput | undefined => {
  if (!offer) {
    return undefined;
  }

  const { expenses, heatPumpOutdoorUnit, heatPumpIndoorUnit, solarPanel, inverter, battery, assortedProducts } = offer;

  return {
    ...pick(offer, ['distributorID', 'chosenHeating', 'reservations']),
    expenses: expenses?.map(expense => toOfferExpenseInput(expense)),
    ...(heatPumpIndoorUnit && { heatPumpIndoorProduct: toProductInput(heatPumpIndoorUnit) }),
    ...(heatPumpOutdoorUnit && { heatPumpOutdoorProduct: toProductInput(heatPumpOutdoorUnit) }),
    ...(solarPanel && { solarPanelProduct: toProductInput(solarPanel) }),
    ...(inverter && { inverterProduct: toProductInput(inverter) }),
    ...(battery && { batteryProduct: toProductInput(battery) }),
    ...(assortedProducts && { assortedProducts: assortedProducts.map(toProductInput) }),
  };
};

type Product = {
  id: number;
  price?: number;
  quantity?: number;
};

const toProductInput = <T extends Product>({ id, quantity, price }: T): Product => ({
  id,
  quantity,
  price,
});

export const toOfferExpenseInput = ({
  templateID,
  notes,
  quantity,
  title,
  description,
  price,
  type,
}: Omit<OfferExpense, 'id' | 'offerID' | 'quantity'> & Partial<Pick<OfferExpense, 'quantity'>>): OfferExpenseInput => ({
  templateID,
  notes,
  quantity: Math.max(Number(quantity), 1),
  title,
  description,
  price: Number(price),
  type,
});
